<template>
    <div class="chartitle">
        在线支持
    </div>
    <div style="display: flex;width: 100%;height: calc(100vh - 120px)">
        <div style="width: 30%;background-color: #eee;height: 100%;box-sizing: border-box;overflow-y: auto">

            <div v-for="chart in chartList" style="display: flex;" class="l-item" @click="currUserId = chart.contact_id"
                :class="currUserId == chart.contact_id ? 'select' : ''">
                <div class="tx">
                    用户
                </div>
                <div style="display: flex;flex-direction: column; justify-content: space-around; margin-left: 10px;">
                    <div>
                        {{ chart.phone }}
                    </div>
                    <div
                        style="font-size: 12px;color: #666; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                        {{ chart.content }}
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 70%;height: 100%;display: flex;flex-direction: column;box-sizing: border-box;padding-left: 10px;"
            v-if="currUserId">

            <div style="flex:1;overflow-y: auto;" id="content">
                <div v-for="i in chart" style="margin: 20px 0;">
                    <div style="display: flex;align-items: center;"
                        :style="{ flexDirection: i.sender_id == 0 ? 'row-reverse' : '' }">
                        <div class="tx">

                            {{ i.sender_id == 0 ? "你" : "用户" }}
                        </div>
                        <div style="margin:0 20px ;background-color: #ccc;padding: 10px;border-radius: 5px;flex-shrink:1;"
                            :style="{ backgroundColor: i.sender_id == 0 ? '#89D961' : '#ffffff' }">
                            {{ i.content }}
                        </div>

                    </div>
                    <div style="text-align: center;font-size: 12px;color: #aaa">
                        {{ i.create_time }}
                    </div>
                </div>
            </div>
            <div style="text-align: right;">
                <textarea name="" id="" style="width: 100%;height: 200px;" v-model="content"
                    placeholder="输入要发送的内容"></textarea>
                <button @click="send"
                    style="width: 100px;height: 30px;margin-top: 10px;cursor: pointer;border-radius: 5px;border:none;background-color: #ddd;color: #08de00;">发送</button>
            </div>

        </div>
    </div>
</template>

<script setup>
import { ref, watch, onUnmounted } from "vue"
import { getMessageList, getMessage } from "@/api/user"
const chartList = ref([])
const currUserId = ref()
const chart = ref([])
const chuangkou = ref()
const content = ref()
getMessageList().then(res => {
    chartList.value = res.data
})
const send = () => {
    let value = content.value

    let data = {
        content: value,
        sender_id: 0,
        receiver_id: currUserId.value
    }
    ws.send(JSON.stringify(data))

}
watch(currUserId, (value) => {
    getMessage(value).then(res => {
        chart.value = res.data
        update()
    })
})

const token = localStorage.getItem("token");
//const ws = new WebSocket('ws://localhost:8099', [token, "admin"]);
const ws = new WebSocket('wss://ws.jimoveebikes.com', [token, "admin"]);



ws.onopen = () => {
    console.log('已连接到服务器');
    //ws.send('Hello Server');
};

ws.onmessage = (event) => {
    console.log('服务器返回:', event.data);
    let data = JSON.parse(event.data)

    let index = -1
    chartList.value.forEach((c, i) => {
        if (c.contact_id == data.sender_id) {
            c.content = data.content
            index = i
        }

    })
    if (index >= 0) {
        let m = chartList.value.splice(index, 1)
        chartList.value.unshift(m[0])
    }else{
        let newChar=data
        newChar.contact_id=data.sender_id
        chartList.value.unshift(newChar)
    }
   

    if (currUserId.value == data.sender_id) {
        chart.value.push({
            content: data.content,
            sender_id: data.sender_id,
            create_time: data.create_time
        })
        console.log("收到用户发的消息");

    }
    if (currUserId.value == data.receiver_id) {
        chart.value.push({
            content: data.content,
            sender_id: 0,
            create_time: data.create_time
        })

        content.value = ""
        let index = -1
        chartList.value.forEach((c, i) => {
            if (c.contact_id == data.receiver_id) {
                c.content = data.content
                index = i
            }




        })

        if (index >= 0) {
            let m = chartList.value.splice(index, 1)
            chartList.value.unshift(m[0])
        }

    }

    if (currUserId.value) {
        update()
    }

};

const update = () => {
    setTimeout(() => {
        const div = document.querySelector('#content'); // 获取目标 div 元素
        div.scrollTop = div.scrollHeight; // 设置滚动条到最底部
       
    }, 50);
}
ws.onclose = () => {
    console.log('连接已关闭');
};
onUnmounted(() => {
    ws.close(1000, "guanbi")
})
</script>

<style scoped>
.tx {
    min-width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #07C160;
    border-radius: 5px;
    color: #fff;
}

.l-item {
    padding: 10px 0;
    padding-left: 10px;
    cursor: pointer;
}

.l-item:hover {
    background-color: #aaa;
}

.select {
    background-color: #999;
}

textarea {
    resize: none;
    border: none;
    border-top: 1px solid #eee;
    padding: 10px;
}

textarea:focus {
    border: none;
    outline: none;
    /* 取消聚焦时的轮廓线 */
}

.chartitle {
    display: flex;
    height: 50px;
    align-items: center;
    background-color: #fff;
    justify-content: center;
}

/* 滚动条整体 */
div::-webkit-scrollbar {
    width: 6px;
    /* 水平滚动条的宽度 */
    height: 6px;
    /* 垂直滚动条的高度 */
}

/* 滚动条轨道 */
div::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* 设置轨道颜色 */
    border-radius: 10px;
}

/* 滚动条滑块 */
div::-webkit-scrollbar-thumb {
    background: #888;
    /* 滑块颜色 */
    border-radius: 10px;
}

/* 滑块在滚动时的颜色变化 */
div::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* 滑块在鼠标悬停时的颜色 */
}
</style>