<template>
 <div class="page">
    <div class="title">
        门店数据分析
    </div>
    <div class="name">
        {{ info.pre_name }} - {{ info.name }}
    </div>


    

    <div class="flex ff">
       
       <div>
           余额:  <span style="color: red;">  {{ info.totalBalance-info.djd-info.ytx }}</span>
       </div>
       <div>
           待解冻: <span style="color: red;"> {{ info.djd }}</span>
       </div>

       <div>
           已提现:<span style="color: red;"> {{ info.ytx }} </span>
       </div>
   
        <div>
            总营收:<span style="color: red;">{{ info.totalBalance }} </span>
        </div>
        <div>
            月营收: <span style="color: red;">{{ info.monthBalance }}</span>
        </div>
        <div>
            日营收:<span style="color: red;"> {{ info.dayBalance }}</span>
        </div>
    
        <div>
            设备数:<span>{{ info.deviceCounts }}</span>
        </div>
        <div>
            租赁中:<span>{{ info.leaseIng }}</span>
        </div>
        <div>
            闲置:<span>{{ info.deviceCounts - info.leaseIng }}</span>
        </div>
        
    </div>

    <div class="t">营收数据</div>

<table>
    <th class="flex item">
    <td>
        月份
    </td>
    <td>
        当月营收
    </td>
    <td>
        平台收入
    </td>
    <td>
        门店分成
    </td>
    <td>
        订单数
    </td>
    <td>
        截止当月设备数
    </td>
    <td>
        月出租率
    </td>
   

    
    </th>
    <tr class="flex item" v-for="i in info.ysList" :key="i.id">
        <td>
            {{ i.time }}
        </td>
        <td>
            {{ i.zys }}
        </td>
        <td>
            {{ i.pt }}
        </td>
        <td>
            {{ i.ys }}
        </td>

        <td>
            {{ i.order_count }}
        </td>
        <td>
            {{ i.totalLeaseCount }}
        </td>
        <td>
            {{ Number(i.order_count/i.totalLeaseCount*100).toFixed(0) }}%
        </td>
       
    </tr>
</table>

    <div class="t">
        设备列表
    </div>

    <table>
        <th class="flex item">
        <td>
            投放时间
        </td>
        <td>
            设备号
        </td>
        <td>
            车架号
        </td>
        <td>
            租赁状态
        </td>
        <td>
            距离门店
        </td>


        <td>
            租赁次数
        </td>

        <td>
            总出租率
        </td>
        </th>
        <tr class="flex item" v-for="i in info.deviceList" :key="i.id">
            <td>
                {{ i.create_time.substring(0,10) }}
            </td>
            <td>
                {{ i.device_number }}
            </td>
            <td>
                {{ i.device_car_number }}
            </td>
            <td>
                {{ i.state ? "出租中" : "闲置" }}
            </td>
            <td>{{ i.d }}</td>
            <td>
                {{ i.lease_count / 30 }}
            </td>

            <td>
                {{ Number(i.xzl).toFixed(2) }}%
            </td>
        </tr>
    </table>


    <div class="t">
        设备分布
    </div>

    <XIAMap :lat="info.lat" :lng="info.lng" :list="list" :fzd="fzd"/>
 </div>
</template>
<script setup>
import { ref, watch } from "vue"
import { getStoreInfo } from '@/api/store'
import { itemInfo } from '@/api/leaseItem'

import { useMainStore } from "/store/mainStore.js"

import XIAMap from "@/components/XIA-Map.vue";
const store = useMainStore();
const list=ref([])

const info = ref({})
const fzd=ref([])
const getDistance=(lat1, lng1, lat2, lng2)=> {
    lat1 = lat1 || 0;
    lng1 = lng1 || 0;
    lat2 = lat2 || 0;
    lng2 = lng2 || 0;

    var rad1 = lat1 * Math.PI / 180.0;
    var rad2 = lat2 * Math.PI / 180.0;
    var a = rad1 - rad2;
    var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
    var r = 6378137;
    var distance = r * 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(rad1) * Math.cos(rad2) * Math.pow(Math.sin(b / 2), 2)));

    return distance;
}
const _get = () => {
    if(store.curr_select_store_id)
    getStoreInfo(store.curr_select_store_id).then(res => {

        if (res.code == 200) {
            list.value=[]
            info.value = res.data
            fzd.value=info.value.fzd
            if(res.data.deviceList)
            info.value.deviceList.forEach(i=>{
                itemInfo({device_number:i.device_number}).then(res=>{

                    if(res.code!==200)
                     {
                        i.d="无位置信息"
                        return
                     }
                    let d =getDistance(info.value.lat,info.value.lng,res.data.latC,res.data.lonC)

                    d=Number(d/1000).toFixed(2)
                    i.d=d + "公里"
                    
                    list.value.push({
                        lat:res.data.latC,
                        lng:res.data.lonC,
                        state:i.state,
                        curr_time:res.data.posDt,
                        device_number:i.device_number,
                        name:info.value.pre_name+info.value.name,
                        pm:info.value.pm
                    })
                    
                }) 
            })
            
        }
    })
}
_get()
watch(() => store.curr_select_store_id, i => {
    _get()
})
</script>
<style lang="scss" scoped>

.t{
    margin: 20px;
    color: #5677fc;
}
.flex {
    div {
        flex: 1;
        text-align: center;
    }
}
.page{
    height: 100%;
    overflow-y: auto;
}
.name {
   
    
    
    margin-top: 20px;
    margin-bottom: 20px;
   text-align: center;
    color: #000;
    padding: 20px;
    
    font-size: 35px;

}



td {
    border-bottom: 1px rgb(160, 187, 213) solid;
    border-right:  1px rgb(160, 187, 213) solid;
}

table,
tr {
    width: 100%;

    td {
        flex: 1;
        text-align: center;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
tr{
   
   
}
.ff{
  
   
    padding-bottom: 20px;
    
    font-size: 16px;
    div{
        flex:1;
        display: flex;
        justify-content: center;
        span{
            margin-left: 20px;

        }
    }
}
table {
    height: 100px !important;
    overflow-y: scroll;
    
}</style>