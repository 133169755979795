import {$post} from "./base"
export async function userindex(params){
    const data=await $post("/user/index",params);
    return data;
}
export async function leaseList(params){
    const data=await $post("/user/leaserecord",params);
    return data;
}
export async function cashFlow(params){
    const data=await $post("/user/cashflow",params);
    return data;
}
/**
 * 还车
 */
export async function getRCR(params){
   
    return $post("/user/rcr",params);
}
export async function rcrTongguo(rcr_id,isHuifu=false,isbohui=false){
   let params=new FormData();
   params.append("rcr_id",rcr_id);
   params.append("isHuifu",isHuifu);
   params.append("isbohui",isbohui);
   
    return $post("/user/rcrTongguo",params);
}
/**
 * 根据订单号结束订单
 * @param {number} order_id 
 */
 export async function endOrderForId(order_id){
    let params=new FormData();
   params.append("order_id",order_id);
    return $post("/user/endOrderForId",params);

}
export async function reendOrderForId(order_id){
    let params=new FormData();
   params.append("order_id",order_id);
    return $post("/user/reendOrderForId",params);

}


/**
 * 根据订单号结束订单
 * @param {number} user_id 
 */
 export async function addBacklistForId(user_id){
    let params=new FormData();
   params.append("user_id",user_id);
    return $post("/user/addBacklistForId",params);

}


export async function idReview(params){
    
    return $post("/user/idReview",params);

}


export async function reviewId(params){
    
    return $post("/user/reviewId",params);

}
export async function userPH(params){
    
    return $post("/user/userPH",params);

}
export async function switchDeviceByOrderNumber(params){
    return $post("/user/switchDeviceByOrderNumber",params);
}

export async function getMessageList(){
    return $post("/user/getMessageList",);
}
export async function getMessage(id){
    return $post("/user/getMessage",{id});
}



